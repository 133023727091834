import { useCallback, useEffect, useState } from "react";
import "firebaseui/dist/firebaseui.css"
import { Form, Button } from "react-bootstrap"
import { FirebaseAuthTrialOrgSignUp1 } from "../../FirebaseAuth";
import LoadingOverlay from "../../components/LoadingOverlay";
import { PossoPhoneInput } from "../../components/PossoPhoneInput";
import { useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import validator from "validator";


export const TrialSignUpOrg = ({ email }) => {

    const [accountCheckResult, setAccountCheckResult] = useState({ result: null, email: null, isEmailInvited: false })
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)
    const [signupForm, setSignupForm] = useState({
        email: email,
        password: "",
        name: "",
        companyName: "",
        phoneNumber: ""
    })

    const navigate = useNavigate()

    const handleEmailChange = (e) => {
        e.preventDefault()
        const value = e.currentTarget.value
        setSignupForm((prevState) => ({
            ...prevState,
            email: value
        }))
    }

    const handlePasswordChange = (e) => {
        e.preventDefault()
        const value = e.currentTarget.value
        setSignupForm((prevState) => ({
            ...prevState,
            password: value
        }))
    }

    const handleNameChange = (e) => {
        e.preventDefault()
        const value = e.currentTarget.value
        setSignupForm((prevState) => ({
            ...prevState,
            name: value
        }))
    }

    const handleCompanyNameChange = (e) => {
        e.preventDefault()
        const value = e.currentTarget.value
        setSignupForm((prevState) => ({
            ...prevState,
            companyName: value
        }))
    }

    const handlePhoneNumberChange = (number) => {
        setSignupForm((prevState) => ({
            ...prevState,
            phoneNumber: number
        }))
    }

    const handleSignup = (e) => {
        e.preventDefault()
        if (!signupForm.password || signupForm.password.length < 7) {
            setErrorMsg("Please provide mimnium 7 characters password.")
            return
        }
        if (!signupForm.name) {
            setErrorMsg("Please provide name.")
            return
        }

        if (!accountCheckResult.isEmailInvited) {
            if (!signupForm.companyName) {
                setErrorMsg("Please provide company name.")
                return
            }

            if (!signupForm.phoneNumber || !isValidPhoneNumber(signupForm.phoneNumber)) {
                setErrorMsg("Please provide valid phone number.")
                return
            }
        }


        setLoading(() => (true))
        FirebaseAuthTrialOrgSignUp1(signupForm, navigate)
    }

    const handleEmailSubmit = (event) => {
        event.preventDefault();

        checkEmail(signupForm.email)
    }

    const checkEmail = useCallback((emailaddress) => {

        if (!validator.isEmail(emailaddress)) {
            setErrorMsg('Please enter correct email address')
            return;
        }

        setLoading(() => (true))
        const requestOptions = {
            method: 'GET',
        };

        fetch(`${process.env.REACT_APP_API_URL}/organization/org_signup_email_check?e=${emailaddress}`, requestOptions)
            .then((res) => {
                setLoading(() => (false))
                res.json()
                    .then((resJson) => {
                        setAccountCheckResult({ result: resJson["status"], email: emailaddress, isEmailInvited: resJson["isEmailInvited"] })
                    })
            })
    }, [])

    useEffect(() => {
        if (email) {
            checkEmail(email)
        }
    }, [checkEmail, email])

    return (
        <>
            <LoadingOverlay loading={loading} />
            <div className="status-message-container mb-2 text-center">
                {errorMsg !== '' && <Form.FloatingLabel className="text-danger">{errorMsg}</Form.FloatingLabel>}
                {accountCheckResult.result === "personal_email" && <Form.FloatingLabel className="text-danger">Please use your work email to sign up.</Form.FloatingLabel>}
                {accountCheckResult.result === "accounts_exists" && <Form.FloatingLabel className="text-danger">This email already signed up. Please <a href={`${process.env.REACT_APP_REDIRECT}?orgsignin`}>sign in</a>.</Form.FloatingLabel>}
                {accountCheckResult.result === "invalid_email" && <Form.FloatingLabel className="text-danger">Please provide correct email address.</Form.FloatingLabel>}
            </div>
            <div className="mx-auto signup-form" style={{ maxWidth: 400 + 'px' }}>
                {accountCheckResult.result !== "continue_with_signup" ?
                    <>
                        <Form onSubmit={handleEmailSubmit}>
                            <Form.Control type="email" className="col mb-3" placeholder="Enter work email" name="email" defaultValue={signupForm.email} onChange={handleEmailChange}/>
                            <div className="col text-end">
                                <Button type="submit" className="mt-4 mb-3 ps-4 pe-4">
                                    Sign up
                                </Button>
                            </div>
                        </Form>
                    </>
                    : <>
                        <Form onSubmit={handleSignup}>
                            <Form.Control type="email" className="col mb-3" placeholder="Enter work email" name="email" value={signupForm.email} disabled={true} />
                            <Form.Control type="password" className="mb-3" id="password" placeholder="Password" onChange={handlePasswordChange} />
                            <Form.Control type="text" className="mb-3" id="name" placeholder="Name" onChange={handleNameChange} />
                            {!accountCheckResult.isEmailInvited && <>
                                <Form.Control type="text" className="mb-3" id="companyname" placeholder="Company Name" onChange={handleCompanyNameChange} />
                                <PossoPhoneInput placeholder="Phone Number" onChange={handlePhoneNumberChange} /></>}

                            <div className="col text-end">
                                <Button type="submit" className="mt-4 ps-4 pe-4 pt-1 pb-1">
                                    Sign up
                                </Button>
                            </div>
                        </Form>
                    </>
                }
            </div>
        </>
    )
}
