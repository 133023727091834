import React from "react";
import { lazy } from "react";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, useLocation, Outlet } from "react-router-dom";
import { Provider } from 'react-redux';

import { ProtectedRoutesAspirant, ProtectedRoutesOrganization, ProtectedRoutesAdmin } from "./ProtectedRoutes";
import LoadingOverlay from "./components/LoadingOverlay";
import AuthAction from "./homepagev2/AuthAction";
import store from "./State/store"

const NotFound = lazy(() => import('./NotFound'))
const AspirantError = lazy(() => import('./AspirantError'))
const AspirantPage = lazy(() => import('./AspirantPage'))
const AspirantProfile = lazy(() => import('./AspirantProfile'))
const AspirantCompanyInterviewV2 = lazy(() => import('./AspirantCompanyInterviewV2'))
const AspirantSelectSkills = lazy(() => import('./AspirantSelectSkills'))
const AspirantResults = lazy(() => import('./AspirantResults'))
const AspirantInterviewReport = lazy(() => import('./AspirantInterviewReport'))
const HomePage = lazy(() => import('./HomePage'))
const HomePageV2 = lazy(() => import('./homepagev2/HomePageV2'))
const ForceLogin = lazy(() => import('./ForceLogin'))
const AspirantSetProfile = lazy(() => import('./AspirantSetProfile'))
const AspirantJobInterview = lazy(() => import('./AspirantJobInterview'))
const AspirantURLInterview = lazy(() => import('./AspirantURLInterview'))
const AspirantLinkedinInterview = lazy(() => import('./AspirantLinkedinInterview'))
const AspirantJobSkillInterview = lazy(() => import('./AspirantJobSkillInterview'))
const AspirantCompletedInterviewError = lazy(() => import('./AspirantCompletedInterviewError'))
const Feedback = lazy(() => import('./Feedback'))
const FullFeedback = lazy(() => import('./FullFeedback'))
const RequestDemo = lazy(() => import('./homepagev2/RequestDemo'))
const InterviewPage = lazy(() => import('./InterviewPage'))

const TeamsInterviewLanding = lazy(() => import('././TeamsInterviewLanding'))

const CandidatesPage = lazy(() => import('./organization/candidate/CandidatesPage'))
const InterviewsPage = lazy(() => import('./organization/interview/InterviewsPage'))
const CreateEditCandidate = lazy(() => import('./organization/candidate/CreateEditCandidate'))
const JobsPage = lazy(() => import('./organization/job/JobsPage'))
const CreateEditJob = lazy(() => import('./organization/job/CreateEditJob'))
const JobPage = lazy(() => import('./organization/job/JobPage'))
const SelectCandidates = lazy(() => import('./organization/candidate/SelectCandidates'))
const CompletedInterviewError = lazy(() => import('./organization/interview/CompletedInterviewError'))
const OrganizationError = lazy(() => import('./organization/OrganizationError'))
const OrganizationVerifyEmail = lazy(() => import('./organization/OrganizationVerifyEmail'))
const QuickInterview = lazy(() => import('./organization/interview/QuickInterview'))
const QuickInterviewSetup = lazy(() => import('./organization/interview/QuickInterviewSetup'))
const OrganizationSettings = lazy(() => import('./organization/settings/OrganizationSettings'))
const OrganizationDashboard = lazy(() => import('./organization/OrganizationDashboard'))
const OrganizationInterviewConfiguration = lazy(() => import('./organization/OrganizationInterviewConfiguration'))
const OrganizationInterviewReport = lazy(() => import('./organization/interview/OrganizationInterviewReport'))
const InterviewCompleted = lazy(() => import('./InterviewCompleted'))
const InterviewLanding = lazy(() => import('./InterviewLanding'))

const AdminError = lazy(() => import('./AdminPortal/Pages/AdminError'))
const AdminHome = lazy(() => import('./AdminPortal/Pages/AdminHome'))
const AdminDashboard = lazy(() => import('./AdminPortal/Pages/AdminDashboard'))
const AdminInterviewReport = lazy(() => import('./AdminPortal/Pages/AdminInterviewReport'))
const AdminOrgJobs = lazy(() => import('./AdminPortal/Pages/AdminOrgJobs'))
const AdminOrgJob = lazy(() => import('./AdminPortal/Pages/AdminOrgJob'))

const PossoError = lazy(() => import('./PossoError'))
const SignInInterstitial = lazy(() => import('./SignInInterstitial'))

const TestWebPubSub = lazy(() => import('./organization/TestWebPubSub'))
const CareerPage = lazy(() => import('./career/CareerPage'))
const CareerDetailPage = lazy(() => import('./career/CareerDetailPage'))

const CodingQuestionPage = lazy(() => import("./Coding/CodingQuestionPage"))

function Layout({ children }) {
  const location = useLocation();
  const pageId = (location.pathname === '/' || location.pathname === '/request_demo' || location.pathname === '/auth/action' || location.pathname === '/careers') ? 'homepagev2' : "app-page"

  return <div id={pageId}><Outlet /></div>
}

const router = createBrowserRouter(createRoutesFromElements(
  <Route element={<Layout />}>
    <Route path='*' element={<NotFound />} errorElement={<PossoError />} />
    <Route path='/oldhome' element={<HomePage />} errorElement={<PossoError />} />
    <Route path='/testwebpubsub/:interviewid' element={<TestWebPubSub />} errorElement={<PossoError />} />
    <Route path='/' element={<HomePageV2 />} errorElement={<PossoError />} />
    <Route path='/careers' element={<CareerPage />} errorElement={<PossoError />} />
    <Route path='/careers/job' element={<CareerDetailPage />} errorElement={<PossoError />} />
    <Route path='/auth/action' element={<AuthAction />} errorElement={<PossoError />} />
    <Route path='/signin' element={<SignInInterstitial />} errorElement={<PossoError />} />
    <Route path='/forceorglogin' element={<ForceLogin />} errorElement={<PossoError />} />
    <Route path='/forceaspirantlogin' element={<ForceLogin />} errorElement={<PossoError />} />
    <Route path='/organization_error' element={<OrganizationError />} errorElement={<PossoError />} />
    <Route path='/organization/completed_interview_error' element={<CompletedInterviewError />} errorElement={<PossoError />} />
    <Route path='/organization/quick_interview/:employer_id/:job_id' element={<QuickInterview />} errorElement={<PossoError />} />
    <Route path='/organization/quick_interview_setup' element={<QuickInterviewSetup />} errorElement={<PossoError />} />
    <Route path='/aspirant_error' element={<AspirantError />} errorElement={<PossoError />} />
    <Route path='/admin_error' element={<AdminError />} errorElement={<PossoError />} />
    <Route path='/full_feedback' element={<FullFeedback />} errorElement={<PossoError />} />
    <Route path='/feedback' element={<Feedback />} errorElement={<PossoError />} />
    <Route path='/request_demo' element={<RequestDemo />} errorElement={<PossoError />} />
    <Route path='/aspirant_select_company' element={<AspirantCompanyInterviewV2 />} errorElement={<AspirantError />} />
    <Route path='/aspirant_select_skills' element={<AspirantSelectSkills />} errorElement={<AspirantError />} />
    <Route path='/aspirant_select_job/:employer_name/:employer_id' element={<AspirantJobInterview />} errorElement={<AspirantError />} />
    <Route path='/aspirant_completed_interview_error' element={<AspirantCompletedInterviewError />} errorElement={<AspirantError />} />
    <Route path='/teams_interview_landing/:interview_id' element={<TeamsInterviewLanding />} errorElement={<AspirantError />} />
    <Route path='/interview/:interview_id' element={<InterviewPage />} errorElement={<AspirantError />} />
    <Route path='/codinginterview/:interview_id' element={<CodingQuestionPage />} errorElement={<AspirantError />} />
    <Route path='/interview_completed' element={<InterviewCompleted />} errorElement={<PossoError />} />
    <Route path='/interview_landing/:interview_id' element={<InterviewLanding />} errorElement={<PossoError />} />

    <Route path='/admin' element={<AdminHome />} errorElement={<PossoError />} />

    <Route element={<ProtectedRoutesAspirant />} >
      <Route path='/aspirant' element={<AspirantPage />} errorElement={<AspirantError />} />
      <Route path='/aspirant_set_up_profile' element={<AspirantSetProfile />} errorElement={<AspirantError />} />
      <Route path='/aspirant_meeting' element={<AspirantURLInterview />} errorElement={<AspirantError />} />
      <Route path='/aspirant_linkedin_scheduled' element={<AspirantLinkedinInterview />} errorElement={<AspirantError />} />
      <Route path='/aspirant_job_skills_scheduled' element={<AspirantJobSkillInterview />} errorElement={<AspirantError />} />
      <Route path='/aspirant_profile' element={<AspirantProfile />} errorElement={<AspirantError />} />
      <Route path='/aspirant_results' element={<AspirantResults />} errorElement={<AspirantError />} />
      <Route path='/aspirant_interview_report/:interview_id' element={<AspirantInterviewReport />} errorElement={<AspirantError />} />
      <Route path="/aspirant_interview2/:interview_id" element={<InterviewPage />} errorElement={<AspirantError />} />
    </Route>

    <Route element={<ProtectedRoutesOrganization />} >
      <Route path='/organization/verify_email' element={<OrganizationVerifyEmail />} errorElement={<OrganizationError />} />
      <Route path='/organization' element={<OrganizationDashboard />} errorElement={<OrganizationError />} />
      <Route path='/organization/settings' element={<OrganizationSettings />} errorElement={<OrganizationError />} />
      <Route path='/organization/candidates' element={<CandidatesPage />} errorElement={<OrganizationError />} />
      <Route path='/organization/interviews'  element={<InterviewsPage />} errorElement={<OrganizationError />} />
      <Route path='/organization/create-candidate' element={<CreateEditCandidate />} errorElement={<OrganizationError />} />
      <Route path='/organization/select-candidates' element={<SelectCandidates />} errorElement={<OrganizationError />} />
      <Route path='/organization/jobs' element={<JobsPage />} errorElement={<OrganizationError />} />
      <Route path='/organization/job/:profile_id' element={<JobPage />} errorElement={<OrganizationError />} />
      <Route path='/organization/create-job' element={<CreateEditJob />} errorElement={<OrganizationError />} />
      <Route path='/organization/edit-job/:profile_id' element={<CreateEditJob />} errorElement={<OrganizationError />} />
      <Route path='/organization/interview_configuration/:profile_id' element={<OrganizationInterviewConfiguration />} errorElement={<OrganizationError />} />
      <Route path='/organization_interview_report/:interview_id' element={<OrganizationInterviewReport />} errorElement={<OrganizationError />} />
    </Route>

    <Route element={<ProtectedRoutesAdmin />} >
      <Route path='/admin/dashboard' element={<AdminDashboard />} errorElement={<AdminError />} />
      <Route path='/admin/organization/jobs/:employer_id' element={<AdminOrgJobs />} errorElement={<AdminError />} />
      <Route path='/admin/organization/job/:employer_id/:profile_id' element={<AdminOrgJob />} errorElement={<AdminError />} />
      <Route path='/admin/interview_report/:interview_id' element={<AdminInterviewReport />} errorElement={<AdminError />} />
    </Route>
  </Route>
))

class App extends React.Component {

  render() {
    return <>
      <Provider store={store}>
        <React.Suspense fallback={<LoadingOverlay loading={true} />}>
          <RouterProvider router={router} />
        </React.Suspense>
      </Provider>
    </>
  }
};

export default App;