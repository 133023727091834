import { useEffect } from "react";
import { useState } from "react";
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input/max";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const PossoPhoneInput = (props) => {
    const [state, setState] = useState({
        country: '',
        phoneNumberWithoutCountry: ''
    });

    useEffect(() => {
        let countryCode = ''
        if (props.phoneNumber) {
            const phone = parsePhoneNumberFromString(props.phoneNumber)

            if (phone) {
                if (phone.countryCallingCode) {
                    countryCode = phone.country

                    setState((prevState) => ({
                        ...prevState,
                        country: phone.country,
                        phoneNumberWithoutCountry: phone.nationalNumber
                    }))
                }
            }
        }
        if (countryCode === '' && state.country === '') {
            const requestOptionsProfile = {
                method: 'GET',
                credentials: 'include'
            };
            fetch(`${process.env.REACT_APP_API_URL}/get_country_code`, requestOptionsProfile)
                .then(res => res.json())
                .then(
                    (result) => {
                        setState((prevState) => ({
                            ...prevState,
                            country: result['country_code']
                        }))
                    })
        }
    }, [props.phoneNumber, state.country])

    return <div>
        <PhoneInput onChange={props.onChange}  international={false} defaultCountry ={state.country} country={state.country} value={props.phoneNumber} placeholder={props.placeholder} className={props.className} countryCallingCodeEditable={false}/>
    </div>

}