import React from 'react';
import { useState, useEffect } from 'react';
import PossoDropdown from './PossoDropdown';
import Modal from "react-bootstrap/Modal";
import { FirebaseAuth } from './FirebaseAuth';
import AuthDropdown from './AuthDropdown';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TrialSignUpOrgModal from './organization/components/TrialSignUpOrgModal';
import OrgSigninModal from './organization/components/OrgSignin';
import { setIsLoggedIn, setUserType, setName, setEmployerId, setEmail } from './State/authSlice';

function AuthUIDropdown(props) {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUser = async () => {
      const requestOptions = {
        method: 'GET',
        credentials: 'include'
      };

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/check_login`, requestOptions);
        const result = await response.json();

        if (JSON.stringify(result) !== JSON.stringify(user)) {
          setUser(result);

          if (result["result"] === "success") {
            dispatch(setIsLoggedIn(true));
            dispatch(setUserType(result["userType"]));
            dispatch(setName(result["name"]));
            dispatch(setEmployerId(result["employer_id"]));
            dispatch(setEmail(result["email"]));
          } else {
            dispatch(setIsLoggedIn(false));
            dispatch(setUserType(null));
            dispatch(setName(""));
            dispatch(setEmployerId(-1));
            dispatch(setEmail(""));
          }
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();

  }, [dispatch, user])

  var content;
  if (user["result"] !== undefined && user["result"] === "success") {
    var userLabel;
    if (user["name"] !== undefined && user["name"] !== "") {
      userLabel = user["name"];
    }
    else {
      userLabel = user["email"];
    }

    content = <PossoDropdown userLabel={userLabel} picture={user["picture"]} userType={user["userType"]} appHeader={props.appHeader} />

  }
  else {
    content = <>
      <AuthDropdown handleShowAsp={props.handleShowAsp} handleShowOrg={props.handleShowOrg} handleShowSignup={props.handleShowSignup} appHeader={props.appHeader} />
    </>
  }

  // CheckLogin();
  return content;
}

function AuthAspirantButton(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return <>
    {
      props.link === 'true'
        ? <Link onClick={handleShow} className="me-2">{props.btnLabel}</Link>
        : <Button onClick={handleShow} variant="outline-primary" className="me-2">{props.btnLabel}</Button>
    }
    <Modal show={show} onHide={handleClose} className="no-border">

      <Modal.Header closeButton className='gr-bg text-light'>
        <button className="btn-close invisible" disabled={true}></button>
        <Modal.Title>Candidate Sign in</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="firebase-auth-container"></div>
        <div className='invisible' id="loader">Loading...</div>
        <FirebaseAuth />
      </Modal.Body>
    </Modal>
  </>
}

function AuthAspirantModal(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  if (show !== props.show) setShow(props.show)

  return <>
    <Modal show={show} onHide={handleClose} className="no-border">

      <Modal.Header closeButton className='gr-bg text-light'>
        <button className="btn-close invisible" disabled={true}></button>
        <Modal.Title>Candidate Sign in</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="firebase-auth-container"></div>
        <div className='invisible' id="loader">Loading...</div>
        <FirebaseAuth redirect={props.redirect} state={props.state} />
      </Modal.Body>
    </Modal>
  </>
}

function AuthUX(props) {
  const [aspSignInModalShow, setAspShow] = useState(false);
  const handleAspClose = () => setAspShow(false);
  const handleAspShow = () => setAspShow(true);

  const [orgSignInModalShow, setOrgShow] = useState(false);
  const handleOrgClose = () => setOrgShow(false);
  const handleOrgShow = () => setOrgShow(true);

  const [orgSignUpModalShow, setSignUpOrgShow] = useState(false);
  const handleOrgSignUpClose = () => setSignUpOrgShow(false);
  const handleOrgSignUpShow = () => setSignUpOrgShow(true);


  return <>
    <AuthUIDropdown handleShowAsp={handleAspShow} handleShowOrg={handleOrgShow} handleShowSignup={handleOrgSignUpShow} appHeader={props.appHeader} />
    <Modal show={aspSignInModalShow} onHide={handleAspClose} className="no-border">

      <Modal.Header closeButton className='gr-bg text-light'>
        <button className="btn-close invisible" disabled={true}></button>
        <Modal.Title>Candidate Sign in</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="firebase-auth-container"></div>
        <div className='invisible' id="loader">Loading...</div>
        <FirebaseAuth />
      </Modal.Body>
    </Modal>
    <OrgSigninModal show={orgSignInModalShow} onHide={handleOrgClose} />
    <TrialSignUpOrgModal show={orgSignUpModalShow} onHide={handleOrgSignUpClose} />
  </>;
}

export default AuthUX;
export { AuthAspirantButton, AuthAspirantModal };